/* eslint-disable */
import React from 'react';
import { renderToString } from 'react-dom/server';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import { api } from './services/api';
import initFontAwesome from './helpers/fontAwesome';
import { initTranslation } from './helpers/t';
import getStore from './modules/store';

// Wrapper components
import { ClientWrapperComponent, SSRWrapperComponent } from './components/RootWrapperComponent';

// Sentry
import './issue-tracking';

// MUI Licensing
LicenseInfo.setLicenseKey('7435ead768f645cdd1cfb0b2d30f9797Tz04MTYzMSxFPTE3MzYwODY4NDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// Listing of React Containers <=> DOM Element associations
const INSTANCES_TO_DOM = {
  navbar: 'navbar',
  listing: 'listing-container', // /listing
  search: 'search-container', // /search
  subscriptions: 'subscriptions-container', // /subscriptions
};

// Server-side rendering
export const getSSRContainer = async (container, window = {}, req = {}) => {
  const { RootComponent, SCOPED_REDUCERS, router, prepare, prefetch } = await import(`./containers/${container}/index`);

  moment.locale(req.userLanguage);

  // Set default store state
  window.defaultState = {
    ...(window.defaultState || {}),
    user: req.userSession
      ? {
        ..._.get(req.userSession.USER, 'data[0]', { id: null }),
        _meta: _.get(req.userSession.USER, 'meta', null)
      }
      : { id: null, _meta: null },
  };

  // Set global window
  global.window = window;

  // Preparing cookie authentication for eventual SSR queries
  const authCookieName = _.get(window.defaultState, 'env.apiCookieName');
  const authCookieValue = _.get(req.cookies, authCookieName);
  const cookies = authCookieValue ? `${authCookieName}=${authCookieValue}` : null;

  // Build an initial state with preloaded data that will be reused for other React instances
  // Prevents duplicated API requests
  if (!req._initalReduxState) {
    const initialStore = getStore({ name: 'initial', preloadedState: false, cookieAuth: cookies });

    initialStore.dispatch(api.endpoints.getCurrentUser.initiate());
    initialStore.dispatch(api.endpoints.getPreload.initiate());

    await Promise.all(initialStore.dispatch(api.util.getRunningQueriesThunk()));

    req._initalReduxState = initialStore.getState();
  }

  // Generate store that will be sent to client
  const store = getStore({
    scope: SCOPED_REDUCERS,
    name: container,
    cookieAuth: cookies,
    preloadedState: req._initalReduxState,
  });

  // Pre-fetching data
  if (prefetch) {
    prefetch(store.getState()).forEach((line) => {
      const { endpoint, parameters, condition } = _.isObject(line) ? line : { endpoint: line };
      if (typeof condition === 'undefined' || condition) {
        store.dispatch(api.endpoints[endpoint].initiate(parameters));
      }
    });
  }
  // Wait for all queries to resolve
  await Promise.all(store.dispatch(api.util.getRunningQueriesThunk()));

  // Extra SSR function that can return data back to express controller
  const extras = prepare
    ? await prepare({ store, window }) || {}
    : {};

  initFontAwesome();
  initTranslation(req.t);

  const WrappedRootComponent = (
    <Provider store={store}>
      <SSRWrapperComponent router={router}>
        <RootComponent />
      </SSRWrapperComponent>
    </Provider>
  );

  return {
    [container]: {
      ssrHTML: renderToString(WrappedRootComponent),
      ssrStoreState: store.getState(),
      ...extras,
    }
  }
};

function initAngular() {
  const html = document.documentElement;
  angular.element(document).ready(function(){
    angular.bootstrap(html, [html.dataset.ngAppName]);

    const event = new Event('LayoutHydrated');
    document.dispatchEvent(event);
  });
}

async function loadInstances() {
  // Prepare instance object for hydration or re-hydration
  for (let path in INSTANCES_TO_DOM) {
    const domContainer = document.getElementById(INSTANCES_TO_DOM[path]);

    if (domContainer) {
      window.__INSTANCES__[path] = {
        ..._.get(window.__INSTANCES__, path, {}),
        domContainer
      }
    }
  }

  // Add hydration listener for initializing Angular once every React instances are ready
  if (!window.__HYDRATION_LISTENER__) {
    window.__HYDRATION_LISTENER__ = true;

    document.addEventListener('_CLIENT_HYDRATED_', (event) => {
      const instance = _.get(event, 'detail.name');

      if (!window.ngAppInit && instance && window.__INSTANCES__[instance] && !window.__INSTANCES__[instance].isHydrated) {
        window.__INSTANCES__[instance].isHydrated = true;

        if (_.every(_.values(window.__INSTANCES__),'isHydrated')) {
          window.ngAppInit = true;
          initAngular();
        }
      }
    });
  }

  // Lazy load React container and hydrate
  for (const instance in window.__INSTANCES__) {
    // Use dynamic imports to lazy load components
    const { RootComponent, SCOPED_REDUCERS, router } = await import(`./containers/${instance}/index.jsx`);

    const WrappedRootComponent = ({ store }) => (
      <Provider store={store}>
        <ClientWrapperComponent router={router} name={instance}>
          <RootComponent />
        </ClientWrapperComponent>
      </Provider>
    );

    const store = window.__INSTANCES__[instance].store || getStore({ scope: SCOPED_REDUCERS, name: instance });

    if (window.__INSTANCES__[instance].isHydrated || window.__INSTANCES__[instance].domContainer.childNodes.length === 0) {
      window.__INSTANCES__[instance] = {
        store,
        domContainer: window.__INSTANCES__[instance].domContainer,
        root: window.__INSTANCES__[instance].root || createRoot(window.__INSTANCES__[instance].domContainer),
        isHydrated: true,
      };

      window.__INSTANCES__[instance].root.render(<WrappedRootComponent store={store} />);
    } else {
      window.__INSTANCES__[instance] = {
        store,
        domContainer: window.__INSTANCES__[instance].domContainer,
        root: hydrateRoot(window.__INSTANCES__[instance].domContainer, <WrappedRootComponent store={store} />)
      }
    }
  }

  // Once done, we delete the preloaded state from window
  if (window.__SSR_STORE_STATE__) {
    delete window.__SSR_STORE_STATE__;
  }

  if (!_.keys(window.__INSTANCES__).length && !window.ngAppInit) {
    window.ngAppInit = true;
    initAngular();
  }
}

// Init translations
const i18nReady = setInterval(async () => {
  if (typeof window !== 'undefined') {
    moment.locale(env.user_language);
    initTranslation();
    initFontAwesome();

    if (window.__INSTANCES__) {
      clearInterval(i18nReady);

      await loadInstances();
    } else if (window.LOCALE) {
      window.__INSTANCES__ = {};
      clearInterval(i18nReady);

      await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
          resources: { [window.LOCALE.locale]: { translation: window.LOCALE } },
          lng: window.LOCALE.locale,
          fallbackLng: window.LOCALE.locale,
          interpolation: { escapeValue: false },
        });

      await loadInstances();
    }
  } else {
    clearInterval(i18nReady);
  }
}, 200);

if (process.env.NODE_ENV !== 'production') {
  // This is used by webpack-dev-server to enable hot reloading
  if (module.hot) {
    module.hot.accept();
  }
}
