import { config, library } from '@fortawesome/fontawesome-svg-core';
import {
  faStar,
  faThumbsUp,
  faBuilding,
  faBolt,
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faGem,
} from '@fortawesome/free-solid-svg-icons';

config.autoAddCss = false; /* eslint-disable import/first */

export default function registerIcons() {
  library.add(
    faStar,
    faThumbsUp,
    faBuilding,
    faBolt,
    faChevronLeft,
    faChevronRight,
    faMapMarkerAlt,
    faGem,
  );
}
